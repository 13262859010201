@use 'ui-kit/theme/units' as u;

.root {
  max-width: 450px;
  align-items: initial;
  padding: u.spacing(1, 2);
  box-shadow: 0px 2px 8px rgba(23, 23, 23, 0.16);
  border-radius: 4px;
  min-width: auto;
  flex-wrap: nowrap;
  box-sizing: border-box;
  background-color: var(--c-black);
  & :global(.MuiSnackbarContent-message) {
    padding: 0;
    flex: 1 0 0%;
  }
  & :global(.MuiSnackbarContent-action) {
    padding: 0;
    margin-right: 0;
  }
}

.errorRoot {
  background-color: var(--c-inactiveRed);
}

.message {
  padding: 0;
}

.divider {
  border-color: var(--c-white);
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: u.spacing(1.5);
  margin-left: u.spacing(1.5);
}
