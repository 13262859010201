/* TODO: make google autocomplete z-index higher then material-ui dialog's 1300 */

.pac-container {
  z-index: 1500;
}

#__next {
  height: 100%;
}

html:not(.hs-messages-widget-open) #hubspot-messages-iframe-container.widget-align-left {
  min-height: 80px !important;
}

@media (max-width: 600px) {
  html:not(.hs-messages-widget-open) {
    #hubspot-messages-iframe-container.widget-align-left {
      bottom: 60px !important;
    }

    body.chat-is-open {
      #hubspot-messages-iframe-container.widget-align-left {
        bottom: 108px !important;
      }
    }
  }
}

.hs-chat-hidden #hubspot-messages-iframe-container {
  display: none !important;
}

html:not(.hs-messages-widget-open) .hs-chat-icon-elevated #hubspot-messages-iframe-container {
  bottom: 64px !important;
}

@media (max-width: 600px) {
  html:not(.hs-messages-widget-open) .hs-chat-icon-elevated #hubspot-messages-iframe-container {
    bottom: 88px !important;
  }
}
