@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;
@use 'ui-kit/theme/layers' as z;

.paper {
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  padding: u.spacing(7, 5, 5);
  @include mq.forPhone {
    padding: u.spacing(9, 3, 3);
  }
}

.header {
  padding: 0;
}

.webViewContainer {
  margin: u.spacing(0);
}

.container {
  margin: u.spacing(0, -2);
  padding: u.spacing(1, 2);
}

.content {
  & > :first-child {
    margin-top: 0;
  }
}

.closeButton {
  position: absolute;
  padding: 12px;
  right: 12px;
  top: 12px;
  z-index: z.$appBar;
}

.actions {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  margin-top: u.spacing(4);
  padding: 0;
  gap: u.spacing(2);

  & > button {
    flex: 1 1 auto;
    min-width: auto;
    width: 100%;
    margin: 0;
  }
}

.actionsLayoutRowReversed {
}

.actionsLayoutColumnReversed {
}

.actionsLayoutDynamic {
  flex-direction: row;
  &.actionsLayoutRowReversed {
    flex-direction: row-reverse;
  }

  @include mq.forPhone {
    flex-direction: column;
    &.actionsLayoutColumnReversed {
      flex-direction: column-reverse;
    }
  }
}

.actionsLayoutRow {
  flex-direction: row;
  &.actionsLayoutRowReversed {
    flex-direction: row-reverse;
  }
}

.actionsLayoutColumn {
  flex-direction: column;
  &.actionsLayoutColumnReversed {
    flex-direction: column-reverse;
  }
}

.actionsSmallLeftAligned {
  @include mq.forTabletUp {
    & > button {
      width: auto;
      flex: 0 1 auto;
    }
  }
}

.actionsLayoutWrap {
  flex-wrap: wrap;
}

.actionsButtonsBreakWords {
  & > button {
    word-wrap: break-word;
    word-break: break-word;
  }
}

.actionsDivider {
  margin: u.spacing(0, -5, -1);
}

.fullScreen {
  border-radius: 0;
}

.wideContainer {
  margin-top: u.spacing(-1);
  padding: u.spacing(0, 2);
}

.paperWithoutPadding {
  padding: 0;
  & .container {
    margin: 0;
    padding: 0;
  }
}

.paperWideMode {
  @include mq.forPhone {
    padding: 0;
  }
  & .header {
    padding: u.spacing(2, 0, 4);
  }
  & .actions {
    margin-top: 0;
  }
  & .actionsDivider {
    margin: u.spacing(0, 0, 2);
  }
}
