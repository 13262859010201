@use 'ui-kit/theme/media' as mq;

.root {
  align-items: center;
  margin-bottom: 68px;
  @include mq.forTabletUp {
    margin-bottom: 0;
    margin-top: 76px;
    align-items: end;
  }
}
