:root {
  --title-font-family: 'Eina02', Arial, sans-serif;
  --body-font-family: Lato, Lato-fallback, sans-serif;
}

a {
  color: var(--c-primaryBlue);
}

// Headings common styles
.pst-hero,
.pst-headingH1,
.pst-headingH2,
.pst-headingH3,
.pst-headingH4,
.pst-headingH5 {
  margin: 0;
  font-family: var(--title-font-family);
  font-weight: bold;
  font-style: normal;
}

.pst-hero {
  line-height: 80px;
  font-size: 68px;
}

.pst-headingH1 {
  line-height: 64px;
  font-size: 55px;
}

.pst-headingH2 {
  line-height: 56px;
  font-size: 44px;
}

.pst-headingH3 {
  line-height: 48px;
  font-size: 35px;
}

.pst-headingH4 {
  line-height: 40px;
  font-size: 28px;
}

.pst-headingH5 {
  line-height: 32px;
  font-size: 22px;
}

// Body common styles
.pst-bodyL,
.pst-bodyLBold,
.pst-body,
.pst-bodyBold,
.pst-bodyS,
.pst-bodySBold,
.pst-bodyXS,
.pst-bodyXSBold,
.pst-link,
.pst-linkS,
.pst-tooltipLink,
.pst-tooltipLinkS,
.pst-inlineLink,
.pst-inlineLinkS {
  margin: 0;
  font-family: var(--body-font-family);
  font-style: normal;
}

.pst-bodyL,
.pst-body,
.pst-bodyS,
.pst-bodyXS {
  font-weight: normal;
}

.pst-bodyLBold,
.pst-bodyBold,
.pst-bodySBold,
.pst-bodyXSBold,
.pst-link,
.pst-linkS,
.pst-tooltipLink,
.pst-tooltipLinkS,
.pst-inlineLink,
.pst-inlineLinkS {
  font-weight: bold;
}

.pst-bodyL,
.pst-bodyLBold {
  line-height: 29px;
  font-size: 22px;
}

.pst-body,
.pst-bodyBold {
  line-height: 28px;
  font-size: 18px;
}

.pst-bodyS,
.pst-bodySBold {
  line-height: 24px;
  font-size: 15px;
}

.pst-bodyXS,
.pst-bodyXSBold {
  line-height: 21px;
  font-size: 14px;
}

.pst-link,
.pst-inlineLink,
.pst-tooltipLink {
  margin: 0;
  text-decoration: none;
  line-height: 28px;
  font-size: 18px;
}

.pst-link:hover,
.pst-linkS:hover {
  text-decoration: underline;
}

.pst-inlineLink,
.pst-inlineLinkS {
  text-decoration: underline;
}

.pst-tooltipLink,
.pst-tooltipLinkS {
  text-decoration: underline dashed;
}

.pst-linkS,
.pst-inlineLinkS,
.pst-tooltipLinkS {
  margin: 0;
  line-height: 24px;
  font-size: 15px;
}

// Mobile styles
@media only screen and (max-width: 599px) {
  .pst-hero {
    line-height: 60px;
    font-size: 46px;
  }

  .pst-headingH1 {
    line-height: 48px;
    font-size: 37px;
  }

  .pst-headingH2 {
    line-height: 40px;
    font-size: 29px;
  }

  .pst-headingH3 {
    line-height: 32px;
    font-size: 24px;
  }

  .pst-headingH4 {
    line-height: 28px;
    font-size: 20px;
  }

  .pst-headingH5 {
    line-height: 24px;
    font-size: 18px;
  }

  .pst-bodyL,
  .pst-bodyLBold {
    line-height: 28px;
    font-size: 18px;
  }

  .pst-body,
  .pst-bodyBold {
    line-height: 24px;
    font-size: 16px;
  }

  .pst-bodyS,
  .pst-bodySBold {
    font-size: 14px;
  }

  .pst-bodyXS,
  .pst-bodyXSBold {
    font-size: 12px;
  }

  .pst-link,
  .pst-inlineLink,
  .pst-tooltipLink {
    line-height: 24px;
    font-size: 16px;
  }

  .pst-linkS,
  .pst-inlineLinkS,
  .pst-tooltipLinkS {
    line-height: 24px;
    font-size: 14px;
  }
}

// Utilities
.pst-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Don't add other alignment properties here
// If you need something more rare - just do it via SCSS module class
// These properties represent 95% of use cases and it's make sense to keep them global for convenience

.pst-left {
  text-align: left;
}

.pst-center {
  text-align: center;
}

.pst-right {
  text-align: right;
}

// Typography component legacy classes
.ps-tp {
  margin: 0;
}

a.ps-tp {
  text-decoration: none;
}

p.ps-tp + p.ps-tp,
p.ps-tp + div.ps-tp,
div.ps-tp + p.ps-tp {
  margin-top: 16px;
}
