@use 'ui-kit/theme/units' as u;

.root {
  align-items: initial;
  padding: u.spacing(1, 2);
  box-shadow: 0px 2px 8px 0px rgba(23, 23, 23, 0.26);
  border-radius: 100px;
  min-width: auto;
  flex-wrap: nowrap;
  box-sizing: border-box;
  background-color: var(--c-white);
  & :global(.MuiSnackbarContent-message) {
    padding: 0;
    flex: 1 0 0%;
  }
  &:hover {
    cursor: inherit;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.messageWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: u.spacing(2);
  color: var(--c-black);
  & > img {
    padding: 0;
    margin: 0;
  }
}
