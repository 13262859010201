/* 
* THIS FILE IS AUTOGENERATED, DO NOT MODIFY IT.
* File: _global-colors.scss, Generated by: create-sass-theme.js
*/

@use '../colors' as c;

:root {
  --c-primaryBlue: #16457e;
  --c-primaryYellow: #ecbc3e;
  --c-secondaryRed: #cc4848;
  --c-secondaryGreen: #246958;
  --c-black: #171717;
  --c-white: #ffffff;
  --c-overlay: #171717bf;
  --c-activeGreen: #128050;
  --c-inactiveRed: #d00000;
  --c-pendingYellow: #ffd871;
  --c-neutralBlue: #0067c7;
  --c-nuclearMango: #ed9630;
  --c-sunShower: #ffe073;
  --c-viricGreen: #94c800;
  --c-muluFrog: #52ba00;
  --c-gray900: #555555;
  --c-gray800: #767676;
  --c-gray700: #ababab;
  --c-gray600: #dddddd;
  --c-gray500: #e8e8e8;
  --c-gray400: #f3f3f3;
  --c-gray300: #fbfbfb;
  --c-pink: #ea00b6;
  --c-purple: #7568f6;
  --c-activeLightGreen: #d7f2e6;
  --c-inactiveLightRed: #ffdede;
  --c-pendingLightYellow: #fff7e3;
  --c-neutralLightBlue: #e4f2ff;
  --c-mysticLightBlue: #e3e8ef;
  --c-marketingPrimaryBlue: #0077b6;
  --c-secondaryBlack: #333333;
  --c-slateBlue: #7a6df8;
  --c-lightMacroPurple: #eddeff;
  --c-fb: #1877f2;
  --c-fbBlue: #16457e;
  --c-slimyGreen: #2dc220;
  --c-summerSky: #3d99ef;
  --c-transparent: #183c6700;
  --c-azureRadiance: #0077b6;
  --c-amberYellow: #ffb200;
  --c-tealGreen: #00a86b;
  --c-orange: #ff9a00;
  --c-intenseRed: #ff4d4d;
  --c-safetyOrange: #e56b00;
}
