/* 
* THIS FILE IS AUTOGENERATED, DO NOT MODIFY IT.
* File: _color-classes.scss, Generated by: create-sass-theme.js
*/

.psc-primaryBlue {
  color: var(--c-primaryBlue);
}
.psc-primaryYellow {
  color: var(--c-primaryYellow);
}
.psc-secondaryRed {
  color: var(--c-secondaryRed);
}
.psc-secondaryGreen {
  color: var(--c-secondaryGreen);
}
.psc-black {
  color: var(--c-black);
}
.psc-white {
  color: var(--c-white);
}
.psc-overlay {
  color: var(--c-overlay);
}
.psc-activeGreen {
  color: var(--c-activeGreen);
}
.psc-inactiveRed {
  color: var(--c-inactiveRed);
}
.psc-pendingYellow {
  color: var(--c-pendingYellow);
}
.psc-neutralBlue {
  color: var(--c-neutralBlue);
}
.psc-nuclearMango {
  color: var(--c-nuclearMango);
}
.psc-sunShower {
  color: var(--c-sunShower);
}
.psc-viricGreen {
  color: var(--c-viricGreen);
}
.psc-muluFrog {
  color: var(--c-muluFrog);
}
.psc-gray900 {
  color: var(--c-gray900);
}
.psc-gray800 {
  color: var(--c-gray800);
}
.psc-gray700 {
  color: var(--c-gray700);
}
.psc-gray600 {
  color: var(--c-gray600);
}
.psc-gray500 {
  color: var(--c-gray500);
}
.psc-gray400 {
  color: var(--c-gray400);
}
.psc-gray300 {
  color: var(--c-gray300);
}
.psc-pink {
  color: var(--c-pink);
}
.psc-purple {
  color: var(--c-purple);
}
.psc-activeLightGreen {
  color: var(--c-activeLightGreen);
}
.psc-inactiveLightRed {
  color: var(--c-inactiveLightRed);
}
.psc-pendingLightYellow {
  color: var(--c-pendingLightYellow);
}
.psc-neutralLightBlue {
  color: var(--c-neutralLightBlue);
}
.psc-mysticLightBlue {
  color: var(--c-mysticLightBlue);
}
.psc-marketingPrimaryBlue {
  color: var(--c-marketingPrimaryBlue);
}
.psc-secondaryBlack {
  color: var(--c-secondaryBlack);
}
.psc-slateBlue {
  color: var(--c-slateBlue);
}
.psc-lightMacroPurple {
  color: var(--c-lightMacroPurple);
}
.psc-fb {
  color: var(--c-fb);
}
.psc-fbBlue {
  color: var(--c-fbBlue);
}
.psc-slimyGreen {
  color: var(--c-slimyGreen);
}
.psc-summerSky {
  color: var(--c-summerSky);
}
.psc-transparent {
  color: var(--c-transparent);
}
.psc-azureRadiance {
  color: var(--c-azureRadiance);
}
.psc-amberYellow {
  color: var(--c-amberYellow);
}
.psc-tealGreen {
  color: var(--c-tealGreen);
}
.psc-orange {
  color: var(--c-orange);
}
.psc-intenseRed {
  color: var(--c-intenseRed);
}
.psc-safetyOrange {
  color: var(--c-safetyOrange);
}
