/* 
* THIS FILE IS AUTOGENERATED, DO NOT MODIFY IT.
* File: _layers.scss, Generated by: create-sass-theme.js
*/

$sliderImage: 2;
$overlayMapCard: 900;
$actionButton: 100;
$componentlocal: 500;
$mobileStepper: 1000;
$speedDial: 1050;
$appBar: 1100;
$drawer: 1200;
$modal: 1300;
$snackbar: 1400;
$tooltip: 1500;
$bottomLayer: -999999;
