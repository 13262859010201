.DayPicker {
  display: flex;
}

.DayPicker-wrapper {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
}

.DayPicker-Months {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
}

.DayPicker-Month {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  display: inline-block;
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;
  margin-top: 2px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  cursor: pointer;
  width: 1.25em;
  height: 1.25em;
  color: #8b9898;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.DayPicker-Weekdays {
  display: flex;
  flex: 0 0 auto;
  user-select: none;
}

.DayPicker-WeekdaysRow {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}

.DayPicker-Weekday {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: auto;
  height: auto;
  user-select: none;
}

.DayPicker-Week {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}

.DayPicker-Day {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.DayPicker-WeekNumber {
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  cursor: pointer;
  color: #4a90e2;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: black;
  font-weight: 700;
}

.DayPicker-Day--outside {
  cursor: default;
  color: #8b9898;
}

.DayPicker-Day--disabled {
  cursor: default;
  color: #dce0e0;
}

/* Spacing */

.DayPicker-Week + .DayPicker-Week {
  margin-top: 7px;
}

.DayPicker-Day + .DayPicker-Day,
.DayPicker-Weekday + .DayPicker-Weekday {
  margin-left: 6px;
}

.DayPicker-Weekdays + .DayPicker-Body {
  margin-top: 8px;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

  background: white;
}

.DayPicker:focus,
.DayPicker-wrapper:focus,
.DayPicker-Months:focus,
.DayPicker-Month:focus,
.DayPicker-Day:focus {
  outline: none;
}
