@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/colors' as c;

$mainPrimary-hover-color: #325b8d;
$mainPrimary-active-color: #133d6f;

$destructivePrimary-hover-color: #d61f1f;
$destructivePrimary-active-color: #b70000;

$destructiveSecondary-active-color: #ffcbcb;

$tertiaryLight-active-color: #d5dde8;

$mainSecondary-active-color: #d5dde8;

$cta-hover-color: #d25e5e;
$cta-active-color: #b43f3f;

$social-active-color: #d5dde8;

.root {
  pointer-events: auto;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-transform: none;
  min-width: max-content;
  padding: var(--button-padding);
}

.rootNoPointerEvents {
  pointer-events: none;
}

.mainPrimary {
  background-color: var(--c-primaryBlue);
  border: 1px solid var(--c-primaryBlue);
  color: var(--button-active-color);
  stroke: var(--button-active-color);
  &:hover {
    background-color: $mainPrimary-hover-color;
    border: 1px solid $mainPrimary-hover-color;
  }
  &:active {
    background-color: $mainPrimary-active-color;
    border: 1px solid $mainPrimary-active-color;
  }
  &:disabled {
    background-color: var(--c-gray500);
    border: 1px solid var(--c-gray500);
    color: var(--button-disabled-color);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.mainSecondary {
  border: 1px solid var(--c-gray600);
  color: var(--button-active-color);
  stroke: var(--button-active-color);
  background-color: var(--c-white);
  &:hover {
    background-color: var(--c-mysticLightBlue);
  }
  &:active {
    background-color: $mainSecondary-active-color;
  }
  &:disabled {
    background-color: var(--c-gray500);
    color: var(--button-disabled-color);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.destructivePrimary {
  background-color: var(--c-inactiveRed);
  border: 1px solid var(--c-inactiveRed);
  color: var(--button-active-color);
  stroke: var(--button-active-color);
  &:hover {
    background-color: $destructivePrimary-hover-color;
    border: 1px solid $destructivePrimary-hover-color;
  }
  &:active {
    background-color: $destructivePrimary-active-color;
    border: 1px solid $destructivePrimary-active-color;
  }
  &:disabled {
    background-color: var(--c-gray500);
    border: 1px solid var(--c-gray500);
    color: var(--button-disabled-color);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.destructiveSecondary {
  border: 1px solid var(--c-gray600);
  background-color: transparent;
  color: var(--button-active-color);
  stroke: var(--button-active-color);
  &:hover {
    background-color: var(--c-inactiveLightRed);
  }
  &:active {
    background-color: $destructiveSecondary-active-color;
  }
  &:disabled {
    color: var(--button-disabled-color);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.tertiaryLight {
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--button-active-color);
  stroke: var(--button-active-color);
  &:hover {
    background-color: var(--c-mysticLightBlue);
  }
  &:active {
    background-color: $tertiaryLight-active-color;
  }
  &:disabled {
    color: var(--button-disabled-color);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.tertiaryDark {
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--button-active-color);
  stroke: var(--button-active-color);
  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.12);
  }
  &:disabled {
    color: var(--button-disabled-color);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.cta {
  background-color: var(--c-secondaryRed);
  border: 1px solid var(--c-secondaryRed);
  color: var(--button-active-color);
  stroke: var(--button-active-color);
  &:hover {
    background-color: $cta-hover-color;
    border: 1px solid $cta-hover-color;
  }
  &:active {
    background-color: $cta-active-color;
    border: 1px solid $cta-active-color;
  }
  &:disabled {
    background-color: var(--c-gray500);
    border: 1px solid var(--c-gray500);
    color: var(--button-disabled-color);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.facebook {
  background-color: var(--c-white);
  color: var(--c-fbBlue);
  stroke: var(--c-fbBlue);
  border: 1px solid var(--c-gray500);
  &:hover {
    background-color: var(--c-mysticLightBlue);
  }
  &:active {
    background-color: $social-active-color;
  }
  &:disabled {
    background-color: var(--c-gray500);
    color: var(--button-disabled-color);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.google {
  background-color: var(--c-white);
  color: var(--c-fbBlue);
  stroke: var(--c-fbBlue);
  border: 1px solid var(--c-gray500);
  &:hover {
    background-color: var(--c-mysticLightBlue);
  }
  &:active {
    background-color: $social-active-color;
  }
  &:disabled {
    background-color: var(--c-gray500);
    color: var(--button-disabled-color);
    border: 1px solid var(--c-gray500);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.apple {
  background-color: var(--c-white);
  color: var(--c-fbBlue);
  stroke: var(--c-fbBlue);
  border: 1px solid var(--c-gray500);
  &:hover {
    background-color: var(--c-mysticLightBlue);
  }
  &:active {
    background-color: $social-active-color;
  }
  &:disabled {
    background-color: var(--c-gray500);
    color: var(--button-disabled-color);
    border: 1px solid var(--c-gray500);
  }
  &:disabled svg {
    filter: grayscale(1);
    opacity: 0.75;
  }
}

.icon {
  display: inline-flex;
  margin-right: 0;
}

.iconWithChildren {
  margin-right: u.spacing(1);
}

.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
  & > svg > * {
    stroke: inherit;
  }
}
